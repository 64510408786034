import React, { Fragment, useEffect, useState } from 'react'

import { useAuth0, withAuth0 } from '@auth0/auth0-react'
import { handleAuthUpdate } from 'lib/url'
import PropTypes from 'prop-types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Provider } from 'react-redux'

import { useInitApp } from '../../../hooks/initApp'
import GlobalStyles from '../../../styles/GlobalStyles'
import ThemeProvider from '../../../styles/ThemeProvider'
import CFCPlayer from '../../CFCPlayer'
import HttpErrorMessage from '../../HttpErrorMessage'
import PlayerErrorBoundary from '../../PlayerErrorBoundary'

const AppWrapperContainer = ({ auth0 }) => {
  const { getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState(null)
  const standaloneMode = !window.parent.__CFC_PLAYER_DATA__

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const entry = params.get('entry')
    const urlToken = params.get('token')

    const fetchToken = async () => {
      if (standaloneMode) {
        try {
          const accessToken = await getAccessTokenSilently()
          setToken(accessToken)
        } catch (error) {
          console.error('Error fetching token:', error)
        }
      }
    }

    if (entry && standaloneMode) {
      if (!urlToken) {
        handleAuthUpdate(auth0) // Log in if no token param
        fetchToken() // Fetch token after logging in
      } else {
        setToken(urlToken)
      }
    }
  }, [getAccessTokenSilently, auth0])

  // Call useInitApp directly in the component
  const { initialStore, error } = useInitApp(token)

  if (!initialStore) {
    return <Fragment />
  }

  const getContentComponent = () => {
    if (error) {
      const { data, status } = error || {}
      const { message } = data || {}

      return <HttpErrorMessage message={message} statusCode={status} />
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <GlobalStyles />
        <PlayerErrorBoundary>
          <CFCPlayer />
        </PlayerErrorBoundary>
      </DndProvider>
    )
  }

  const contentComponent = getContentComponent()

  return (
    <Provider store={initialStore}>
      <ThemeProvider>{contentComponent}</ThemeProvider>
    </Provider>
  )
}

AppWrapperContainer.propTypes = {
  auth0: PropTypes.object, // Can be removed if not passed as a prop
}

export default withAuth0(AppWrapperContainer)
