/* istanbul ignore file */
import { backoff } from '@cfc/exponential-backoff'

const FETCH_RETRY_ATTEMPTS = 1

const core = async (url, method, data, swallowErrors) => {
  try {
    const options = { method }
    const { token, fetchedToken } = data.data || {}
    const authToken = token || (fetchedToken ? `Bearer ${fetchedToken}` : null)

    switch (method) {
      case 'GET':
        // TODO: turn data into a query string and append to URL
        break
      case 'POST':
      // falls through
      case 'PUT':
        // TODO: refactor this check to see if one of the fields contains files
        if (
          Object.keys(data).some(
            k =>
              Array.isArray(data[k]) &&
              data[k].length &&
              'name' in data[k][0] &&
              'size' in data[k][0] &&
              'type' in data[k][0],
          )
        ) {
          const formData = new FormData()
          Object.keys(data).forEach(k => {
            if (Array.isArray(data[k]) && data[k].length) {
              data[k]
                .filter(f => f.size)
                .forEach((f, i) => {
                  formData.append(`packages[${i}]`, f)
                })
            } else {
              formData.append(k, data[k])
            }
          })

          options.body = formData
        } else {
          options.body = JSON.stringify(data)
          options.headers = {
            'Content-Type': 'application/json',
          }
        }
    }

    // Add Conditional Header
    // __CFC_TOKEN__ will come from the window when launched in LMS
    // CFC_TOKEN will come from our local env variable when launched locally
    // If neither exist, the call will just not work
    let jwtToken = null
    try {
      jwtToken = window.parent.__CFC_TOKEN__
    } catch (err) {
      console.warn('Unable to get token from parent', err)
    }

    options.headers = {
      ...options.headers,
      Authorization: jwtToken
        ? `Bearer ${jwtToken}`
        : authToken || `Bearer ${process.env.CFC_TOKEN}`,
    }

    const response = await backoff(
      () => fetch(url, options),
      FETCH_RETRY_ATTEMPTS,
    )

    if (!response.ok) {
      const msg = `Failed fetch: ${response.status} (${response.statusText})`

      if (swallowErrors) {
        console.warn(msg, 'error')
      }
    }
    return {
      status: response.status,
      data: await response.json(),
    }
  } catch (err) {
    const msg = `Failed fetch:\n${err}`

    if (swallowErrors) {
      console.warn(msg, 'error')
    }

    return { status: 500, data: null }
  }
}

export default class Ajax {
  static async get(url, data, swallowErrors = false) {
    return await core(url, 'GET', data, swallowErrors)
  }

  static async post(url, data, swallowErrors = false) {
    return await core(url, 'POST', data, swallowErrors)
  }

  static async put(url, data, swallowErrors = false) {
    return await core(url, 'PUT', data, swallowErrors)
  }
}
