import { setCookie } from 'utils/cookiesHelper'
import {
  isHighSchoolMode as checkHighSchoolMode,
  isHighSchoolPreviewMode as checkHighSchoolPreviewMode,
} from 'utils/playerHelper'

import {
  CACHE,
  DEFAULT_THEME,
  ENTRY,
  LOCALE,
  MODE,
  PREVIEW,
  THEME,
  TOKEN,
} from '../../components/AppWrapper/constants'
import { cmiLaunchDataSelector } from '../state/selectors'

import {
  CURRICULUM_PREVIEW,
  DEFAULT,
  FAMILY,
  getModeFromQueryParam,
  TEACHER_PREP,
} from './modes'

export const getLocationData = () => {
  const { search } = location || {}
  const params = search && new URLSearchParams(search)

  return {
    search,
    params,
  }
}

export const getModeParams = urlParams => {
  // the query param mode trumps the deprecated prep, curriculumPreview, and family params
  const mode = getMode(urlParams)
  const isPrepMode = !!mode && mode === TEACHER_PREP
  const isFamilyMode = !!mode && mode === FAMILY
  const isCurriculumPreviewMode = !!mode && mode === CURRICULUM_PREVIEW

  return {
    isPrepMode,
    isFamilyMode,
    isCurriculumPreviewMode,
  }
}

export const getParams = (urlParams, store) => {
  const contentfulId = getEntryId(urlParams, store)
  const themeId = getTheme(urlParams, store)
  const preview = getPreviewParam(urlParams) // Not a mode; should we use the contentful preview host or not?
  const token = getTokenParam(urlParams)
  const cache = getCacheParam(urlParams)
  const locale = getLocale(urlParams)

  const { isPrepMode, isFamilyMode, isCurriculumPreviewMode } = getModeParams(
    urlParams,
  )

  const isHighSchoolMode = checkHighSchoolMode()
  const isHighSchoolPreviewMode = checkHighSchoolPreviewMode()

  const paramsObject = {
    contentfulId,
    preview,
    themeId,
    token,
    cache,
    locale,
    isPrepMode,
    isFamilyMode,
    isCurriculumPreviewMode:
      isCurriculumPreviewMode && !isHighSchoolPreviewMode,
    isHighSchoolMode,
    isHighSchoolPreviewMode,
  }

  return paramsObject
}

export const getEntryId = (urlParams, store) => {
  const storeState = (store && store.getState()) || {}
  const launchData = cmiLaunchDataSelector(storeState) || {}
  const { entry } = launchData || {}
  const hasEntryId =
    urlParams && typeof urlParams === 'object' && urlParams.has(ENTRY)
  const entryId = hasEntryId ? urlParams.get(ENTRY) : entry

  return entryId
}

export const getTheme = (urlParams, store) => {
  const storeState = (store && store.getState()) || {}
  const launchData = cmiLaunchDataSelector(storeState) || {}
  const paramsTheme = urlParams.get(THEME)
  const { theme: launchDataTheme } = launchData || {}
  const themeId = paramsTheme || launchDataTheme || DEFAULT_THEME

  return themeId
}

// Returns a string of either 'true' or 'false'
export const getPreviewParam = urlParams => {
  const hasPreview = urlParams.has(PREVIEW)
  const preview = hasPreview
    ? urlParams.get(PREVIEW)?.toLowerCase() === 'true'
      ? 'true'
      : 'false'
    : 'false'

  return preview
}

export const getLocale = urlParams => {
  return urlParams.get(LOCALE)
}

export const getTokenParam = urlParams => {
  const token = urlParams.get(TOKEN)
  return token || null
}

export const getCacheParam = urlParams => {
  return urlParams.get(CACHE)
}

export const getMode = urlParams => {
  if (urlParams.has(MODE)) {
    return getModeFromQueryParam(urlParams.get(MODE))
  }

  return DEFAULT
}

export function getQueryParams(url) {
  const query = url.split('?')[1]

  if (!query) {
    return {}
  }

  const params = new URLSearchParams(query)
  const queries = {}

  for (const [key, value] of params.entries()) {
    queries[key] = value
  }

  return queries
}

export const handleAuthUpdate = auth0 => {
  if (!auth0.isAuthenticated && !auth0.error) {
    const pathname =
      window.location.pathname + window.location.search + window.location.hash
    const loginOptions = getQueryParams(window.location.href)
    setCookie('pathname', pathname)
    // Guard to ensure loginOptions contains the 'entry' field
    if (loginOptions.entry) {
      // Construct the redirect_uri dynamically
      const baseDomain = window.location.origin
      const queryParams = new URLSearchParams({
        ...loginOptions, // Include all existing login options
      }).toString()
      const redirectUri = `${baseDomain}/callback`

      // Add redirect_uri to loginOptions
      loginOptions.redirect_uri = redirectUri
      // Perform the redirect with loginOptions and appState
      auth0.loginWithRedirect({
        ...loginOptions,
        appState: { queryParams },
      })
    } else {
      console.error(
        'loginOptions does not contain the entry field:',
        loginOptions,
      )
    }
  }
}
