// Load environment variables
const SUBDOMAIN = process.env.SUBDOMAIN || ''
const HOST_DOMAIN = process.env.HOST_DOMAIN || ''
const AUTH_CLIENT_ID = process.env.AUTH_CLIENT_ID || ''

// Construct the Auth0 settings
export const auth0Settings = {
  domain: `login${SUBDOMAIN}.${HOST_DOMAIN}`,
  audience: 'secondstep-apis',
  clientId: AUTH_CLIENT_ID,
}
